<template>
  <div v-if="user">
      <v-navigation-drawer
        v-if="!$vuetify.display.mobile"
          v-model="drawer"
          :rail="rail"
          permanent
          @mouseover="rail = false" 
          @mouseleave="rail = true" 
          
      >
        <div class="d-flex flex-column justify-space-between h-100">
          <div>

            <v-list density="compact" nav>
                <v-list-item prepend-icon="mdi-home" title="Главная" value="home" to="/app"></v-list-item>
                <v-list-item prepend-icon="mdi-checkbox-marked-circle-outline" title="Задачи" value="tasks" to="/app/boards/my" v-if="getBoardRole"></v-list-item>
                <v-list-item prepend-icon="mdi-calendar-edit" title="Календарь" value="calender" to="/app/calender" v-if="getCalenderRole"></v-list-item>
                <v-list-item prepend-icon="mdi-cart-outline" title="Закупки" value="purchases" to="/app/purchases" v-if="getPurchasesRole"></v-list-item>
                <v-list-item prepend-icon="mdi-pin" title="Заметки" value="notes" to="/app/notes" v-if="getNotes"></v-list-item>
                <v-list-item prepend-icon="mdi-table-edit" title="Отпуска и больничные" value="vacationSickLeave" to="/app/vacationSickLeave" v-if="getVacationSickLeave"></v-list-item>
                <v-list-item prepend-icon="mdi-calendar-text-outline" title="Расписание экзаменов" value="nokCalender" to="/app/nok" v-if="getNok"></v-list-item>
                <v-list-item prepend-icon="mdi-cog" title="Настройки" value="profile" to="/app/settings"></v-list-item>
                <v-list-item prepend-icon="mdi-account-group" title="Панель управления" value="manage" to="/app/manage" v-if="user.isAdmin"></v-list-item>
            </v-list>
          </div>

          <div class="d-flex justify-space-between">
            
            <v-list-item
              :prepend-avatar="user.profile.avatar ? $storagePath + user.profile.avatarImg.fileName : ''"
              :title="`${user.profile.name} ${user.profile.surname}`"
              :subtitle="user.email"
              nav
              height="63px">
              <template v-slot:prepend v-if="!user.profile.avatar">
                <div class="avatar d-flex justify-center align-center text-subtitle-2 font-weight-bold">
                  {{ user.profile.name.substr(0,1) }}
                </div>
              </template>
              <template v-slot:append>
                <v-btn
                variant="text"
                icon="mdi-chevron-left"
                size="small"
                @click.stop="rail = !rail"
                ></v-btn>
            </template>
            

          </v-list-item>
        </div>
          
        </div>
    </v-navigation-drawer>
    <v-app-bar
        color="surface"
        v-if="$vuetify.display.mobile"
        prominent
      >
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="text-subtitle- font-weight-bold">{{ mobileTitle }}</v-toolbar-title>
      </v-app-bar>
      <v-navigation-drawer
      v-if="$vuetify.display.mobile"
        v-model="drawer"
        location="top"
      >
        <v-list>
                <v-list-item prepend-icon="mdi-home" title="Главная" value="home" to="/app" @click="mobileTitle = 'Главная'"></v-list-item>
                <v-list-item prepend-icon="mdi-checkbox-marked-circle-outline" title="Задачи" value="tasks" to="/app/boards/my" v-if="getBoardRole" @click="mobileTitle = 'Задачи'"></v-list-item>
                <v-list-item prepend-icon="mdi-calendar-edit" title="Календарь" value="calender" to="/app/calender" v-if="getCalenderRole" @click="mobileTitle = 'Календарь'"></v-list-item>
                <!-- <v-list-item prepend-icon="mdi-cart-outline" title="Закупки" value="purchases" to="/app/purchases" v-if="getPurchasesRole" @click="mobileTitle = 'Закупки'"></v-list-item>
                <v-list-item prepend-icon="mdi-table-edit" title="Отпуска и больничные" value="vacationSickLeave" to="/app/vacationSickLeave" v-if="getVacationSickLeave" @click="mobileTitle = 'Отпуска и больничные'"></v-list-item> -->
                <v-list-item prepend-icon="mdi-cog" title="Настройки" value="profile" to="/app/settings" @click="mobileTitle = 'Настройки'"></v-list-item>
                <!-- <v-list-item prepend-icon="mdi-account-group" title="Панель управления" value="manage" to="/app/manage" v-if="user.isAdmin" @click="mobileTitle = 'Панель управления'"></v-list-item> -->
        </v-list>
      </v-navigation-drawer>

    <v-main theme="dark">
      <slot />
    </v-main>
  </div>
</template>



<script>
import { defineComponent } from 'vue'

import { useServicesStore } from '@/store/services';

export default defineComponent({
  name: "CpLayout",
  data(){
    return {
      rail: true,
      drawer: true,
      mobileTitle: 'Главная'
    }
  },
  setup() {
    const services = useServicesStore();

    return { services };
  },
  
  computed: {
      user(){
        return this.$auth.user();
      },
      getBoardRole(){
        if(this.user.services.length == 0) return 0;
          return this.user.services.find(object => object.serviceId == this.services.services.find(item => item.name == 'task-manager')?.id)?.roleId
      },
      getCalenderRole(){
        if(this.user.services.length == 0) return 0;
            return this.user.services.find(object => object.serviceId == this.services.services.find(item => item.name == 'calender')?.id)?.roleId
      },
      getPurchasesRole(){
        if(this.user.services.length == 0) return 0;
            return this.user.services.find(object => object.serviceId == this.services.services.find(item => item.name == 'purchases')?.id)?.roleId
      },
      getVacationSickLeave(){
        if(this.user.services.length == 0) return 0;
            return this.user.services.find(object => object.serviceId == this.services.services.find(item => item.name == 'vacationSickLeave')?.id)?.roleId
      },
      getNok(){
        if(this.user.services.length == 0) return 0;
            return this.user.services.find(object => object.serviceId == this.services.services.find(item => item.name == 'nok-calender')?.id)?.roleId
      },
      getNotes(){
        if(this.user.services.length == 0) return 0;
            return this.user.services.find(object => object.serviceId == this.services.services.find(item => item.name == 'notes')?.id)?.roleId
      },
  },
  mounted() {
    if(!this.$auth.check()) this.$router.push('/login')
    this.services.initialize();

    this.$notification.getActiveNotifications()
    console.log(this.$auth.check());
    
  }
})

// socket.io-client vue использвание

</script>

<style lang="scss" scoped>

.cp-layout{
  .slot{
      width: calc(1100px - 300px);
  }

  .user-card:hover{
      background: rgba(var(--v-theme-surfaceOpacity), 0.05);
      cursor: pointer;
  }
}


.council-img{
width: 35px;
height: 35px;
border-radius: 100px;
object-fit: cover;

}

.avatar{
  width: 35px;
  height: 35px;
  border-radius: 100px;
  background: rgba(var(--v-theme-surfaceOpacity), 0.06);
  margin-left: 3px;
  margin-right: 15px;
  }

</style>



