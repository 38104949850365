import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";


export const useNotificationStore = defineStore('notification', () => {
    const activeNotifications = ref([]);
    const notifications = ref([]);

    const getActiveNotifications = async () => {
        let response = await axios.get('/notifications/my')

        activeNotifications.value = response.data
    }

    const getNotifications = async (page, perPage) => {
        page = page ?? 1;
        perPage = perPage ?? 20;

        let response = await axios.get('/notifications/all', {
            params: {
                page: page,
                perPage: perPage
            }
        })

        notifications.value = response.data
    }

    const readNotification = async (id) => {
        await axios.put(`/notification/${id}/read`);

        let index = activeNotifications.value.findIndex(notification => notification.id == id);
        activeNotifications.value.splice(index, 1)
    }


    return {activeNotifications, notifications, getActiveNotifications, getNotifications, readNotification}
});