import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";


export const useServicesStore = defineStore('services', () => {
    const services = ref([]);

    const initialize = () => {
        axios.get('/services/get').then((response) => {
            services.value = response.data;
        }).catch((error) => {
            console.log(error);
        });
    }
    

    return {
        services,
        initialize
    }
});