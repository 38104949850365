import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../pages/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Auth/SignIn.vue')
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../pages/Auth/SignUp.vue')
  // },
  {
    path: '/restore/:id/:token',
    name: 'restore',
    component: () => import('../pages/Auth/ResetPassword.vue')
  },
  {
    path: '/restore-request',
    name: 'restore-request',
    component: () => import('../pages/Auth/ResetPasswordRequest.vue')
  },
  {
    path: '/confirm/:id/:token',
    name: 'confirm',
    component: () => import('../pages/Auth/Confirm.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../pages/App/Index.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/manage',
    name: 'manage',
    component: () => import('../pages/App/Manage.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/calender',
    name: 'calender',
    component: () => import('../pages/App/Calender.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/notes',
    name: 'notes',
    component: () => import('../pages/App/Notes.vue'),
    meta:{
        layout: "cp-layout"
    }
  },{
    path: '/app/nok',
    name: 'nok',
    component: () => import('../pages/App/Nok.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/boards',
    name: 'boards',
    component: () => import('../pages/App/Boards.vue'),
    meta:{
        layout: "cp-layout"
    },
    children: [{ 
      path: '/app/boards/my', 
      component: () => import('../components/Boards/MyBoards.vue'),
    }, { 
      path: '/app/boards/all', 
      component: () => import('../components/Boards/AllBoards.vue'),
    }, { 
      path: '/app/boards/reports', 
      component: () => import('../components/Boards/Reports.vue'),
    }, { 
      path: '/app/boards/archive', 
      component: () => import('../components/Boards/Archive.vue'),
    }]
  },
  {
    path: '/app/board/:id',
    name: 'board',
    component: () => import('../pages/App/Board.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/purchases',
    name: 'purchases',
    component: () => import('../pages/App/Purchases.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/purchases/:id',
    name: 'purchasesBoard',
    component: () => import('../pages/App/PurchasesBoard.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/settings',
    name: 'settings',
    component: () => import('../pages/App/Settings.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/vacationSickLeave',
    name: 'vacationSickLeave',
    component: () => import('../pages/App/VacationSickLeave.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/fileedit',
    name: 'fileedit',
    component: () => import('../pages/FileEdit.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
