/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts }         from './webfontloader'
import vuetify               from './vuetify'
import pinia                 from '../store'
import router                from '../router'
import axios                 from 'axios';
import {createAuth}          from '@websanova/vue-auth';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import VueAxios              from "vue-axios";
import CKEditor from '@ckeditor/ckeditor5-vue';

import { useAlertStore }     from '../store/alert'
import { useNotificationStore }     from '../store/notifications'

import infiniteScroll from 'vue-infinite-scroll'
import aonkId from '@/plugins/aonkId.js';


// axios.defaults.baseURL = 'http://cok.expertspk.ru/api/V1'


if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8010'
  // axios.defaults.backUrl = 'http://cok.expertspk.ru/'
  axios.defaults.backUrl = 'http://localhost:8081'
}else{
  axios.defaults.baseURL = 'https://api.crm.ao-nk.ru'
  // axios.defaults.backUrl = 'http://cok.expertspk.ru/'
  axios.defaults.backUrl = 'https://crm.ao-nk.ru'
}

var auth = createAuth({
  plugins: {
    http: axios,
    router: router
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: driverRouterVueRouter,
    oauth2: {
      aonkId: aonkId,
    }
  },
  options: {
    authRedirect: '/auth/login',
  }
})

export function registerPlugins (app) {
  loadFonts()
  app
    .use(vuetify)
    .use(router)
    .use( CKEditor )
    .use(VueAxios, axios)
    .use(pinia)
    .use(auth)
    .use(infiniteScroll)


    app.config.globalProperties.$auth = auth;
    app.config.globalProperties.$alert = useAlertStore();
    app.config.globalProperties.$notification = useNotificationStore();
    // app.config.globalProperties.$storagePath = "";
    // app.config.globalProperties.$wsUrl = "ws://localhost:8006";

    if (process.env.NODE_ENV === 'development') {
      app.config.globalProperties.$storagePath = "http://10.0.1.231:8010/public/";
      app.config.globalProperties.$wsUrl = "ws://10.0.1.231:8011";
      app.config.globalProperties.$backUrl = "http://10.0.1.231:8081";
    }else{
      app.config.globalProperties.$storagePath = "https://api.crm.ao-nk.ru/public/";
      app.config.globalProperties.$wsUrl = "https://api.crm.ao-nk.ru";
      app.config.globalProperties.$backUrl = "https://crm.ao-nk.ru";
    }

    pinia.$auth = auth;
}


// использвать сокет во vue