import { defineStore } from "pinia";


export const useAlertStore = defineStore('alert', {
  state: () => ({
    alerts: []
  }),
  actions: {
    addErrorAlert(message){
        let id = new Date().getTime();
        this.alerts.push({
            id: id,
            type: 'error',
            show: true,
            message: message
        })

        setTimeout(() => {
            this.removeAlert(id);
        }, 6000);
    },
    addWarnAlert(message){
        let id = new Date().getTime();

        this.alerts.push({
            id: id,
            type: 'warning',
            show: true,
            message: message
        })

        setTimeout(() => {
            this.removeAlert(id);
        }, 6000);
    },
    addSuccessAlert(message){
        let id = new Date().getTime();
        
        this.alerts.push({
            id: id,
            type: 'success',
            show: true,
            message: message
        })

        setTimeout(() => {
            this.removeAlert(id);
        }, 6000);
    },
    removeAlert(id){
        this.alerts[this.alerts.findIndex(object => object.id == id)].show = false;
    }
  }
})