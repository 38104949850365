let config
if (process.env.NODE_ENV == 'development') {
  config={
    url: 'http://10.0.1.205:3000/oauth',

    params: {
      // ид клиента
      clientId: 'dde56be4-141d-4fca-9483-9f0615fc9540',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'phone',
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar',
        'birthday',
        'rfCitizen',
        'inn',
        'gender',
        'postAddress',
        'regionCode',
        'snils',
        'city'],
    }
  }
} else {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      // ид клиента
      clientId: 'f31a0e52-83ca-408e-9ea1-8ec725dd5a1f',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'phone',
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar',
        'birthday',
        'rfCitizen',
        'inn',
        'gender',
        'postAddress',
        'regionCode',
        'snils',
        'city'],
    }
  }
}

module.exports = config
